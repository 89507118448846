:host {
	display: flex;
	inline-size: 100%;
	align-items: center;
	padding-block: .5rem;
	padding-inline: 2rem 1rem;
	gap: .5rem;
	font-size: .9rem;
	border-radius: inherit;
}

span:first-of-type {
	flex: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: start;
}

span:last-of-type {
	display: none;
	padding-block: .1rem;
	padding-inline: .2rem;
	font-size: .8rem;
	font-weight: bold;
	color: var(--color-on-surface-variant);
}

img {
	block-size: 1.5rem;
	inline-size: auto;
}

:host(.unread) > span:first-of-type {
	font-weight: bold;
}

:host(.unread) > span:last-of-type {
	display: block;
}
